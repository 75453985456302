import { actions, values } from 'storage';
import { MaterialApiCalls } from 'api';

const _MBWidgetCart = async (materialSku, itemMetadata = null) => {
  if (values.isFetchingItem) {
    return {
      isOk: true
    };
  }

  actions.setIsFetchingItem(true);

  const response = await MaterialApiCalls.checkIfSkuIsValid(materialSku);
  const {
    data: { results, message = null },
    isOk
  } = response;

  if (!isOk) {
    actions.setIsFetchingItem(false);
    return {
      isOk: false,
      data: {
        title: 'Something went wrong.',
        message: message || 'Please try again!'
      }
    };
  }

  if (!results || !results.length) {
    actions.setIsFetchingItem(false);
    return {
      isOk: false,
      data: {
        title: message || 'Item not available for sampling.',
        message: 'Please select another material or contact us.'
      }
    };
  }

  const shouldAllowOutOfStock = String(values?.initObject?.brandId) === '741';
  const filteredResult = results.filter((item) => item.product_type === 'simple')?.[0] || results?.[0];

  if (!shouldAllowOutOfStock && filteredResult?.qty === 0) {
    actions.setIsFetchingItem(false);
    return {
      isOk: false,
      data: {
        title: 'Out of stock.',
        message: 'This item cannot be ordered.'
      }
    };
  }
  
  const itemSku = filteredResult.sku;
  const maxItems = filteredResult.qty < 3 ? filteredResult.qty : 3;

  actions.storeItemToLocalStorage(itemSku, itemMetadata, maxItems);
  actions.setIsFetchingItem(false);

  return {
    isOk: true,
    data: { message: 'Stored item' }
  };
};

export default _MBWidgetCart;
