import { CartApiCalls, MaterialApiCalls } from 'api';
import Axios from 'axios';
import { CurrenciesEnum } from 'enums';
import { values } from 'storage';
import { countryStates, getDeliveryDate, getMaterialData } from 'utils';
import { searchByKeyEndpoint } from '../constants/network';
import { isUAT } from '../constants/environment';

async function fetchMaterialsForSku(sku, useManufacturerSku = false){
  const filterManufacturer = values.initObject.theme === 'steelcase-light' ?['Designtex', 'Steelcase, Inc', 'Steelcase Inc'] : undefined;
  const skuFilterKey = useManufacturerSku ? 'manufacturer_sku' : 'sku';
  const { data } = await Axios.post(
    searchByKeyEndpoint,
    {
      "siteId": "materialbank_product",
      "fieldsOnly": true,
      "extraFields": [
        "is_in_stock",
        "family_id",
        "color",
        "primary_color"
      ],
      'filter.manufacturer': filterManufacturer,
      [`key.${skuFilterKey}`]:sku,
      "filter.is_child": true
    }
  );
  return data;
}

export async function getMaterialsForSku(sku, useManufacturerSku = false){
  const data =  await fetchMaterialsForSku(sku, useManufacturerSku);
  if (data?.results?.length >= 1){
    return data;
  } else{
    //if there are no items for sku, try to fetch for manufacturerSku
    return await fetchMaterialsForSku(sku, !useManufacturerSku)
  }

}

export async function getMaterialForSku(sku, useManufacturerSku = false){
 const data = await getMaterialsForSku(sku, useManufacturerSku)
  return await getMaterialData(data, sku);
}

const getLocalStorageCartItems = async () => {
  const {
    initObject: { manufacturer }
  } = values;
  const cartItemsSkus = values.cartItems;
  let cartItems = [];

  if (!cartItemsSkus) {
    return {
      isOk: true,
      data: []
    };
  }

  const skuArray = Object.keys(cartItemsSkus);

  try {
    for (const sku of skuArray) {
      const numberOfItemsAddedToCart = cartItemsSkus[sku];
      const material = await getMaterialForSku(sku);

      cartItems = [
        ...cartItems,
        {
          material,
          numberOfItemsAddedToCart,
          sku
        }
      ];
    }
    return {
      data: cartItems,
      isOk: true
    };
  } catch (e) {
    console.log('error', e)
    return {
      data: e,
      isOk: false
    };
  }
};

const getCartItems = async (skuArray) => {
  const {
    initObject: { manufacturer }
  } = values;
  let cartItems = [];

  try {
    for (const sku of skuArray) {
      const material = await getMaterialForSku(sku);
      cartItems = [
        ...cartItems,
        {
          material,
          numberOfItemsAddedToCart: 1,
          sku
        }
      ];
    }

    return {
      data: cartItems,
      isOk: true
    };
  } catch (e) {
    return {
      data: e,
      isOk: false
    };
  }
};

const placeOrder = async (projectName, projectType) => {
  const { cartItems, address, initObject } = values;
  const brandSourceId = initObject?.brandId;
  const products = [];
  const { data: searchCartItems } = await getLocalStorageCartItems();
  const outOfStockItem = searchCartItems.find(item => item.numberOfItemsAddedToCart > (item.material.qty ?? 0));
  const shouldAllowOutOfStock = String(initObject?.brandId) === '741';

  if (!shouldAllowOutOfStock && outOfStockItem?.material?.name) {
    return {
      isOk: false,
      data: `${outOfStockItem?.material?.name} is out of stock.`,
      message: 'Please remove this item to proceed with your order'
    }
  }

  for (const [sku, qty] of Object.entries(cartItems)) {
    const metadata = values.itemsMetadata?.[sku];
    products.push({ sku, qty, metadata });
  }

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    address2,
    city,
    state,
    zipCode,
    country
  } = address;

  if (address1.length > 49 || address2.length > 49) {
    const exceedingFieldLabel = address1.length > 49 ? 'Address 1' : 'Address 2';

    return {
      isOk: false,
      data: 'The address is too long.',
      message: `${exceedingFieldLabel} has to be less than 50 characters.`
    }
  }

  const { states } = countryStates[country] || [{}];

  const { label: stateLabel } = states.find(({ value }) => state === value);

  const customer = {
    firstname: firstName,
    lastname: lastName,
    email,
    phone: phoneNumber,
    projectName,
    projectType: projectType || null,
    shippingAddress: {
      street: address1,
      address2,
      apt: '',
      city,
      state: stateLabel,
      postcode: zipCode,
      country_id: country
    }
  };

  const order = {
    brandSourceId,
    customer,
    products,
    widgetOrderMetadata: {
      ...window
      .widgetOrderMetadata,
      ...values.extraMetadata
    }
  };

  if (values.shippingMethod != null) {
    order.widgetOrderMetadata.shippingMethod = values.shippingMethod;
  }

  try {
    const { isOk, data: brandConfigData } = await CartApiCalls.getBrandConfiguration();
    if (!isOk || !brandConfigData) {
      return {
        isOk: false,
        data: 'Invalid brandSource'
      };
    }

    const { groupId } = brandConfigData;

    order.customer.groupId = parseInt(groupId);
const orderPrefix  = isUAT ? 'uat2' : 'www';

    const { data } = await Axios.post(
      `https://${orderPrefix}.materialbank.com/rest/V1/sdg-api/widget_order`,
      order
    );

    if (!data || !data.length || data[0].is_error) {
      return {
        data,
        isOk: false
      };
    }

    return {
      data: data[0],
      isOk: true
    };
  } catch (e) {
    return {
      data: e,
      isOk: false
    };
  }
};

const sendOrderEmail = async ({ items, order }) => {
  try {
  const { numberOfItemsAddedToCart: total, itemsMetadata, initObject, shippingMethod = 'overnight' } = values;
  const {
    date,
    id,
    customer = {
      address: {}
    }
  } = order;
  const { orderDate } = getDeliveryDate();

  const orderDetails = {
    date,
    number: id,
    total,
    deliveryDate: orderDate.toString()
  };
  const cartItems = [];
  const isItemMetadata = Object.keys(itemsMetadata).length > 0;

  let samplesPrice = 0;

  for (const item of items) {
    const imageUrl =
      item.imageUrl && item.imageUrl.replace('uat.materialbank.com', 'www.materialbank.com');
    let metadata = isItemMetadata ? itemsMetadata[item.sku] : null;
    let materialPrice = 0;

    const { isOk: useManufacturerSku, data: manufacturerSku } = await MaterialApiCalls.getManufacturerSku(item.sku);
    const sku = useManufacturerSku && manufacturerSku ? manufacturerSku : item.sku;

    const material = await getMaterialForSku(sku,useManufacturerSku)
    if (metadata?.priceOverride) {
      samplesPrice += item.qty * Number(metadata.priceOverride);
      materialPrice = Number(metadata?.priceOverride);
    } else if (material?.sample_price != null) {
      samplesPrice += item.qty * Number(material?.sample_price);
      materialPrice = Number(material?.sample_price);
    }

    const finalImageUrl = imageUrl || (material && material.imageUrl) || results[0].imageUrl;

    cartItems.push({
      ...item,
      brand: material?.brand || null,
      imageUrl: finalImageUrl,
      sku,
      price: metadata?.priceOverride ? Number(metadata.priceOverride) : materialPrice
    });
  }

  const cartMetadata = window.widgetOrderMetadata?.cart || null;
  const costData = {
    currencySymbol: initObject?.currencySymbol || '$',
    currencyName: CurrenciesEnum[initObject?.currencySymbol || '$'],
    samplesPrice,
    price: { Samples: samplesPrice }
  };

  cartMetadata?.forEach((cartItem) => {
    if (cartItem.name) {
      costData.price[cartItem.name] = cartItem.value;
    }
  });

  const showPricing = values.initObject.showPricing ?? false;

  const data = {
    order: orderDetails,
    customer,
    cartItems,
    emailTemplate: initObject?.emailTemplate || 'default',
    costData,
    showPricing,
    shippingMethod
  };
    const { isOk, data: brandConfigData = {} } = await CartApiCalls.getBrandConfiguration();

    if (!isOk) {
      return {
        isOk: false,
        data: 'Could not send email because of invalid email address'
      };
    }

    const brandName = brandConfigData.brandName;

    data.brandConfiguration = { ...brandConfigData, brandName };

    const res = fetch('https://tvrtd6z7q3.execute-api.us-west-2.amazonaws.com/default/send-email', {
      method: 'POST',
      body: JSON.stringify(data)
    });

    return {
      data: res,
      isOk: true
    };
  } catch (e) {
    return {
      data: e,
      isOk: false
    };
  }
};

const getBrandConfiguration = async () => {
  const { initObject } = values;
  const brandId = initObject?.brandId;

  try {
    const { data = {} } = await Axios.get(
      `https://tvrtd6z7q3.execute-api.us-west-2.amazonaws.com/default/configuration?id=${brandId}`
    );

    return {
      data,
      isOk: true
    };
  } catch (e) {
    console.error(e)
    return {
      data: e,
      isOk: false
    };
  }
};

export default {
  getLocalStorageCartItems,
  getCartItems,
  placeOrder,
  sendOrderEmail,
  getBrandConfiguration
};
